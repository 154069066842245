import React from "react"
import {Page} from "@indebted/components/Page"
import {Grid, Typography} from "@material-ui/core"
function Rejected() {
	return (
		<Page>
			<Grid item style={{width: "100%"}}>
				<Typography variant="body2">
					Thank you for discussing this with us. At this time, a payment arrangement doesn&apos;t appear to be
					the best solution for your situation.
				</Typography>
			</Grid>

			<Grid item style={{width: "100%"}}>
				<Typography variant="body2">
					In circumstances like yours, we recommend seeking independent debt advice.
				</Typography>
			</Grid>

			<Grid item style={{width: "100%"}}>
				<Typography variant="body2">
					To give you some breathing space and the opportunity to access free and impartial advice, we will
					place your account on hold for one month.
				</Typography>
			</Grid>

			<Grid item>
				<Typography variant="h6" align="center">
					Recommended Resources:
				</Typography>
			</Grid>

			<Grid item>
				<Typography variant="h6" align="center">
					StepChange
				</Typography>
				<Typography variant="body2" align="center">
					0800 138 1111
				</Typography>
				<Typography variant="body2" align="center">
					<a href="https://www.stepchange.org">www.stepchange.org</a>
				</Typography>
			</Grid>

			<Grid item>
				<Typography variant="h6" align="center">
					National Debtline
				</Typography>
				<Typography variamnt="body2" align="center">
					0800 808 4000
				</Typography>
				<Typography variant="body2" align="center">
					<a href="https://www.nationaldebtline.org">www.nationaldebtline.org</a>
				</Typography>
			</Grid>

			<Grid item>
				<Typography variant="h6" align="center">
					Citizens Advice Bureau
				</Typography>
				<Typography variant="body2" align="center">
					<a href="https://www.citizensadvice.org.uk">www.citizensadvice.org.uk</a>
				</Typography>
			</Grid>
		</Page>
	)
}

export {Rejected}
